import styled, { css } from "styled-components"
import CloseIcon from "components/atoms/CloseIcon"

const StyledSuccessWrapper = styled.div`
  > h2,
  p {
    margin-bottom: 1.6rem;
  }
  padding: 8rem ${({ theme }) => theme.layout.mobilePadding} 0;
  ${({ theme }) => theme.mq.medium} {
    padding: 11.6rem 0;
  }
`

const StyledWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;

  ${({ theme }) => theme.mq.medium} {
    max-width: 140rem;
    max-height: 80rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 80%;
  }
`
const StyledContentWrapper = styled.div`
  flex: 1;

  ${({ theme }) => theme.mq.medium} {
    margin-bottom: 0;
    overflow: hidden;
  }
`
const StyledCloseButton = css`
  position: absolute;
  top: 2.8rem;
  left: 2rem;
  border: none;
  background: none;
  width: 1.6rem;
  height: 1.6rem;
  padding: 0;
  min-height: initial;

  &:hover {
    background: none;
  }

  ${({ theme }) => theme.mq.medium} {
    top: 6rem;
    left: 0;
  }
`
const StyledCloseIcon = styled(CloseIcon)`
  width: 100%;
  height: 0.2rem;
  right: 0;
  left: initial;
`

export {
  StyledSuccessWrapper,
  StyledWrapper,
  StyledContentWrapper,
  StyledCloseButton,
  StyledCloseIcon,
}
