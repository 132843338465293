import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { StyledLabel, StyledInput, StyledCheckMark } from "./Checkbox.styles"

const Checkbox = ({
  labelText,
  id,
  register,
  checked,
  extraStyles,
  className,
  onChange,
  ...inputProps
}) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  const handleOnChange = (e) => {
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value
    onChange(value)
    setIsChecked(value)
  }
  return (
    <>
      <StyledLabel
        htmlFor={id}
        data-testid="checkboxLabel"
        className={className}
      >
        {labelText}
        <StyledInput
          type="checkbox"
          id={id}
          {...inputProps}
          ref={register}
          data-testid="checkboxInput"
          checked={isChecked}
          onChange={handleOnChange}
        />
        <StyledCheckMark data-testid="checkboxMark" extraStyles={extraStyles} />
      </StyledLabel>
    </>
  )
}
Checkbox.propTypes = {
  labelText: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  register: PropTypes.func,
  extraStyles: PropTypes.any,
  className: PropTypes.string,
  checked: PropTypes.bool,
}

Checkbox.defaultProps = {
  register: () => null,
  onChange: () => null,
  extraStyles: null,
  checked: false,
  className: "",
}
export default Checkbox
