/* stylelint-disable selector-type-no-unknown */
import styled from "styled-components"

const StyledLabel = styled.label`
  display: block;
  position: relative;
  padding-left: 35px;
  cursor: pointer;
  user-select: none;
  font-size: 12px;
  letter-spacing: 0.25px;
  width: fit-content;
  color: ${({ theme }) => theme.color.secondary01};
`
const StyledCheckMark = styled.span`
  position: absolute;
  top: -0.4rem;
  left: 0;
  height: 2.4rem;
  width: 2.4rem;
  border: solid 1px ${({ theme }) => theme.color.secondary04};
  ${({ extraStyles }) => extraStyles}

  &:after {
    content: "";
    position: absolute;
    display: none;
    left: 0.8rem;
    top: 0.3rem;
    width: 0.7rem;
    height: 1.4rem;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
  }
`

const StyledInput = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;

  &:checked ~ ${StyledCheckMark} {
    background-color: ${({ theme }) => theme.color.main03};
  }

  &:checked ~ ${StyledCheckMark}:after {
    display: block;
  }
`

export { StyledLabel, StyledInput, StyledCheckMark }
