import React from "react"
import PropTypes from "prop-types"

import { useIntl } from "gatsby-plugin-intl"

import paths from "utils/paths"

import H from "components/atoms/H"
import P from "components/atoms/P"
import Checkbox from "components/atoms/Checkbox"
import Button from "components/atoms/Button"
import Spinner from "components/atoms/Spinner"

import {
  mainTitleStyles,
  subTitleStyles,
  mainTextStyles,
  checkboxStyles,
  tableParagraphStyles,
  buttonStyles,
  buttonInfoStyles,
  StyledSection,
  StyledTextWrapper,
  StyledTablesWrapper,
  StyledTableWrapper,
  StyledTableHeader,
  StyledTableContent,
  StyledTableItem,
  StyledButtonContainer,
  StyledButtonWrapper,
} from "./UnsubscribeForm.styles"

const UnsubscribeForm = ({
  formData: {
    data: {
      subscriptions: {
        subscription: {
          contact: { email },
        },
      },
    },
  },
  emailProjects,
  phoneProjects,
  handleEmailProjectSelectionToggle,
  handlePhoneProjectSelectionToggle,
  handleToggleAllSelect,
  unselectAll,
  sendForm,
  loaderVisible,
  error,
}) => {
  const { formatMessage } = useIntl()
  const { home } = paths

  return (
    <StyledSection>
      <StyledTextWrapper>
        <H as="h1" extraStyles={mainTitleStyles}>
          {formatMessage({ id: "unsubscribe.mainHeader" })}
        </H>
        <H as="h3" extraStyles={subTitleStyles}>
          {formatMessage({ id: "unsubscribe.subHeader" })}
        </H>
        <P extraStyles={mainTextStyles}>
          {formatMessage({ id: "unsubscribe.mainText" })}
        </P>
        <P>{formatMessage({ id: "unsubscribe.subText" })}</P>
      </StyledTextWrapper>
      <StyledTablesWrapper>
        <StyledTableWrapper>
          <StyledTableHeader>
            <P>{formatMessage({ id: "unsubscribe.table.emailTitle" })}</P>
            <div data-testid="unsubscribeUnselectAllCheckbox">
              <Checkbox
                id="emailUnselectAll"
                onClick={() => handleToggleAllSelect("email")}
                labelText={formatMessage({
                  id: "unsubscribe.table.unselectAll",
                })}
                extraStyles={checkboxStyles}
                checked={unselectAll.email}
              />
            </div>
          </StyledTableHeader>
          <StyledTableContent>
            <P extraStyles={tableParagraphStyles}>
              {`${formatMessage({
                id: "unsubscribe.table.emailSubTitle",
              })} `}
              <span>{email}</span>
            </P>

            {emailProjects &&
              emailProjects.map((project) => (
                <StyledTableItem key={project.project.id}>
                  <Checkbox
                    id={`${project.project.id}email`}
                    onClick={() =>
                      handleEmailProjectSelectionToggle(project.project.id)
                    }
                    labelText={project.project.name}
                    checked={!project.unselected}
                  />
                </StyledTableItem>
              ))}
          </StyledTableContent>
        </StyledTableWrapper>
        <StyledTableWrapper>
          <StyledTableHeader>
            <P>{formatMessage({ id: "unsubscribe.table.phoneTitle" })}</P>
            <div data-testid="unsubscribeUnselectAllCheckbox">
              <Checkbox
                id="phoneUnselectAll"
                onClick={() => handleToggleAllSelect("phone")}
                labelText={formatMessage({
                  id: "unsubscribe.table.unselectAll",
                })}
                extraStyles={checkboxStyles}
                checked={unselectAll.phone}
              />
            </div>
          </StyledTableHeader>
          <StyledTableContent>
            {phoneProjects.map((phoneProject) => (
              <div key={phoneProject.phoneNumber}>
                <P extraStyles={tableParagraphStyles}>
                  {`${formatMessage({
                    id: "unsubscribe.table.phoneSubTitle",
                  })} `}
                  <span>{phoneProject.phoneNumber}</span>
                </P>
                {phoneProject.subscribeBlog && (
                  <StyledTableItem>
                    <Checkbox
                      id={`${phoneProject.id}${phoneProject.phoneNumber}blog`}
                      onClick={() =>
                        handlePhoneProjectSelectionToggle(
                          "blog",
                          phoneProject.phoneNumber
                        )
                      }
                      labelText="Blog"
                      checked={!phoneProject.unsubscribeBlog}
                    />
                  </StyledTableItem>
                )}

                {phoneProject.phoneProjectSubscriptions &&
                  phoneProject.phoneProjectSubscriptions.map((project) => (
                    <StyledTableItem
                      key={`${project.project.id}${phoneProject.phoneNumber}`}
                    >
                      <Checkbox
                        id={`${project.project.id}phone${phoneProject.phoneNumber}`}
                        onClick={() =>
                          handlePhoneProjectSelectionToggle(
                            project.project.id,
                            phoneProject.phoneNumber
                          )
                        }
                        labelText={project.project.name}
                        checked={!project.unselected}
                      />
                    </StyledTableItem>
                  ))}
              </div>
            ))}
          </StyledTableContent>
        </StyledTableWrapper>
      </StyledTablesWrapper>
      <StyledButtonContainer>
        <H as="h3">{formatMessage({ id: "unsubscribe.thanks" })}</H>
        {error && (
          <P extraStyles={buttonInfoStyles}>
            {formatMessage({ id: "unsubscribe.info" })}
          </P>
        )}
        <StyledButtonWrapper>
          <Button to={home} extraStyles={buttonStyles}>
            {formatMessage({ id: "unsubscribe.buttons.cancel" })}
          </Button>

          <Button
            extraStyles={buttonStyles}
            type="secondary"
            onClick={sendForm}
          >
            {formatMessage({ id: "unsubscribe.buttons.unsubscribe" })}
          </Button>
          {loaderVisible && <Spinner />}
        </StyledButtonWrapper>
      </StyledButtonContainer>
    </StyledSection>
  )
}

UnsubscribeForm.propTypes = {
  formData: PropTypes.shape({
    data: PropTypes.shape({
      subscriptions: PropTypes.shape({
        subscription: PropTypes.shape({
          contact: PropTypes.shape({
            email: PropTypes.string,
          }),
        }),
      }),
    }),
  }).isRequired,
  emailProjects: PropTypes.arrayOf(
    PropTypes.shape({
      project: PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
      }),
    })
  ).isRequired,
  phoneProjects: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      phoneNumber: PropTypes.string,
      phoneProjectSubscriptions: PropTypes.arrayOf(
        PropTypes.shape({
          project: PropTypes.shape({
            id: PropTypes.string,
            name: PropTypes.string,
          }),
        })
      ),
      subscribeBlog: PropTypes.bool,
    })
  ).isRequired,
  handleEmailProjectSelectionToggle: PropTypes.func.isRequired,
  handlePhoneProjectSelectionToggle: PropTypes.func.isRequired,
  handleToggleAllSelect: PropTypes.func.isRequired,
  unselectAll: PropTypes.shape({
    email: PropTypes.bool,
    phone: PropTypes.bool,
  }).isRequired,
  sendForm: PropTypes.func.isRequired,
  loaderVisible: PropTypes.bool.isRequired,
  error: PropTypes.any.isRequired,
}

export default UnsubscribeForm
