import React from "react"
import { useIntl } from "gatsby-plugin-intl"

import paths from "utils/paths"

import H from "components/atoms/H"
import P from "components/atoms/P"
import BtnLink from "components/atoms/Button"

import {
  StyledSuccessWrapper,
  StyledWrapper,
  StyledContentWrapper,
  StyledCloseButton,
  StyledCloseIcon,
} from "./SucessMessage.styles"

const SuccessMessage = () => {
  const { formatMessage } = useIntl()
  const { home } = paths

  return (
    <StyledWrapper data-testid="unsubscribeWrapper">
      <BtnLink
        extraStyles={StyledCloseButton}
        to={home}
        data-testid="unsubscribeCloseButton"
      >
        <StyledCloseIcon />
      </BtnLink>
      <StyledContentWrapper data-testid="subscribeContentWrapper">
        <StyledSuccessWrapper data-testid="unsubscribeSuccessWrapper">
          <H as="h2">
            {formatMessage({ id: "unsubscribe.successMessage.title" })}
          </H>
          <P>{formatMessage({ id: "unsubscribe.successMessage.message" })}</P>
        </StyledSuccessWrapper>
      </StyledContentWrapper>
    </StyledWrapper>
  )
}

export default SuccessMessage
