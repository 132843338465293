import React, { useState, useEffect } from "react"
import PropTypes from "prop-types"
import { navigate } from "gatsby-plugin-intl"
import { ThemeProvider } from "styled-components"
import theme from "styles/theme"
import GlobalStyles from "styles/GlobalStyles"

import SEO from "components/molecules/SEO"
import UnsubscribeForm from "components/molecules/UnsubscribeForm"
import SuccessMessage from "components/molecules/UnsubscribeForm/SuccessMessage"

import postUnsubscribe from "utils/postUnsubscribe"

const Unsubscribe = ({ location }) => {
  const [token, setToken] = useState(null)
  const [data, setData] = useState(null)
  const [unselectAll, setUnselectAll] = useState({ email: false, phone: false })
  const [emailProjects, setEmailProjects] = useState([])
  const [phoneProjects, setPhoneProjects] = useState([])
  const [error, setError] = useState(null)
  const [success, setSuccess] = useState(null)

  const [loaderVisible, setLoaderVisible] = useState(false)

  useEffect(() => {
    const id = location.search.replace("?token=", "")

    if (!id) {
      navigate("/")
    } else {
      setToken(id)
    }
  }, [])

  const handleEmailProjectSelectionToggle = (projectId) => {
    const arrCopy = emailProjects.map((proj) => {
      if (proj.project.id === projectId)
        return { ...proj, unselected: !proj.unselected }
      return proj
    })

    setEmailProjects(arrCopy)
    if (unselectAll.email === true)
      setUnselectAll({ ...unselectAll, email: false })
  }

  const handlePhoneProjectSelectionToggle = (projectId, phoneNumber) => {
    const getPhoneProjectData = phoneProjects.find(
      (arr) => arr.phoneNumber === phoneNumber
    )
    if (projectId === "blog") {
      const phoneProjectData = {
        ...getPhoneProjectData,
        unsubscribeBlog: !getPhoneProjectData.unsubscribeBlog,
      }

      const getAllPhoneData = phoneProjects.map((item) => {
        if (item.phoneNumber === phoneNumber) {
          return phoneProjectData
        }
        return item
      })

      setPhoneProjects(getAllPhoneData)
    } else {
      const getProjectList = getPhoneProjectData.phoneProjectSubscriptions.map(
        (proj) => {
          if (proj.project.id === projectId)
            return { ...proj, unselected: !proj.unselected }
          return proj
        }
      )

      const phoneProjectData = {
        ...getPhoneProjectData,
        phoneProjectSubscriptions: getProjectList,
      }

      const getAllPhoneData = phoneProjects.map((item) => {
        if (item.phoneNumber === phoneNumber) {
          return phoneProjectData
        }
        return item
      })

      setPhoneProjects(getAllPhoneData)
    }

    if (unselectAll.phone === true)
      setUnselectAll({ ...unselectAll, phone: false })
  }

  const handleToggleAllSelect = (type) => {
    if (type === "email") {
      const arrCopy = emailProjects.map((proj) => {
        const projCopy = { ...proj }
        projCopy.unselected = !unselectAll.email
        return projCopy
      })
      setEmailProjects(arrCopy)
      setUnselectAll({ ...unselectAll, email: !unselectAll.email })
    }

    if (type === "phone") {
      const arrCopy = phoneProjects.map((item) => {
        const projectList = item.phoneProjectSubscriptions.map((proj) => {
          const projCopy = { ...proj }
          projCopy.unselected = !unselectAll.phone
          return projCopy
        })

        return item.subscribeBlog
          ? {
              ...item,
              phoneProjectSubscriptions: projectList,
              unsubscribeBlog: !unselectAll.phone,
            }
          : { ...item, phoneProjectSubscriptions: projectList }
      })

      setPhoneProjects(arrCopy)
      setUnselectAll({ ...unselectAll, phone: !unselectAll.phone })
    }
  }

  const sendForm = async () => {
    setLoaderVisible(true)
    const response = await postUnsubscribe({
      token,
      emailProjects,
      phoneProjects,
    })

    const {
      errors,
      data: { unsubscribe },
    } = response

    setSuccess(unsubscribe)
    setError(errors)
    setLoaderVisible(false)
  }

  const getData = async (dataToken) => {
    const response = await fetch(process.env.GATSBY_API_URL, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Basic  ${process.env.GATSBY_API_BASE_AUTH}`,
      },
      body: JSON.stringify({
        query: `query{
          subscriptions(token: "${dataToken}") {
            subscription {
              contact {
                id
                email
              }
              phoneSubscriptions {
                id
                phoneNumber
                phoneProjectSubscriptions {
                  project {
                    name
                    id
                  }
                }
                subscribeBlog
              }
              projectSubscriptions {
                project {
                  name
                  id
                }
              }
              subscribeBlog
              subscribePortfolio
            }
          }
                  }`,
        variables: null,
      }),
    })
    const body = await response.json()

    if (
      (body && body.errors) ||
      (body && body.data.subscriptions.subscription === null)
    ) {
      navigate("/")
    } else {
      setData(body)
      setEmailProjects(
        body.data.subscriptions.subscription.subscribeBlog
          ? [
              {
                project: {
                  name: "Blog",
                  id: "blog",
                },
              },
              ...body.data.subscriptions.subscription.projectSubscriptions,
            ]
          : body.data.subscriptions.subscription.projectSubscriptions
      )
      setPhoneProjects(body.data.subscriptions.subscription.phoneSubscriptions)
    }
  }

  useEffect(() => {
    if (token) getData(token)
  }, [token])

  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      <SEO title="Update subscription" />
      {data && !success && (
        <UnsubscribeForm
          formData={data}
          emailProjects={emailProjects}
          phoneProjects={phoneProjects}
          unselectAll={unselectAll}
          handleEmailProjectSelectionToggle={handleEmailProjectSelectionToggle}
          handlePhoneProjectSelectionToggle={handlePhoneProjectSelectionToggle}
          handleToggleAllSelect={handleToggleAllSelect}
          sendForm={sendForm}
          loaderVisible={loaderVisible}
          error={error}
        />
      )}
      {success && <SuccessMessage />}
    </ThemeProvider>
  )
}

Unsubscribe.propTypes = {
  location: PropTypes.shape({
    search: PropTypes.string,
  }).isRequired,
}

export default Unsubscribe
