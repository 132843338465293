import styled, { css } from "styled-components"

const StyledSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: ${({ theme }) => theme.gridWidth};
  margin: 0 auto;
  height: 100%;
  padding: 7.2rem 0 3.2rem;
`

const StyledTextWrapper = styled.div`
  text-align: center;
  margin-bottom: 7.2rem;
  padding: 0 ${({ theme }) => theme.layout.mobilePadding};
`

const mainTitleStyles = css`
  margin-bottom: 2.4rem;
`
const subTitleStyles = css`
  margin-bottom: 3.2rem;
`
const mainTextStyles = css`
  font-size: ${({ theme }) => theme.font.size.l};
`
const checkboxStyles = css`
  border-color: ${({ theme }) => theme.color.secondary01};
`
const tableParagraphStyles = css`
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  margin-bottom: 1.6rem;

  span {
    font-size: ${({ theme }) => theme.font.size.s};
    font-weight: ${({ theme }) => theme.font.weight.regular};
  }
`
const buttonInfoStyles = css`
  font-size: ${({ theme }) => theme.font.size.md};
  font-weight: ${({ theme }) => theme.font.weight.semiBold};
  color: ${({ theme }) => theme.color.tertiary02};
  margin-top: 2.4rem;
`

const StyledTablesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;

  ${({ theme }) => theme.mq.medium} {
    flex-direction: row;
    justify-content: center;
    margin: 0;
  }
`
const StyledTableWrapper = styled.div`
  width: 100%;
  max-width: 45rem;
  padding: 1.6rem;

  ${({ theme }) => theme.mq.medium} {
    padding: 0 1.6rem;
  }
`
const StyledTableHeader = styled.div`
  background: ${({ theme }) => theme.color.secondary04};
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4.4rem;
  padding: 0 2.4rem;
`
const StyledTableContent = styled.div`
  padding: 1.8rem 2.4rem;
  display: flex;
  flex-direction: column;
  border: 0.2rem solid ${({ theme }) => theme.color.secondary04};
  max-height: 28rem;
  height: 100%;
  overflow: auto;
`

const StyledTableItem = styled.div`
  min-height: 2.4rem;
  margin: 0.4rem 0;
`

const StyledButtonContainer = styled.div`
  width: 100%;
  max-width: 90rem;
  margin: 8.6rem auto 0;
  padding: 0 ${({ theme }) => theme.layout.mobilePadding};
`

const StyledButtonWrapper = styled.div`
  margin-top: 3.2rem;
  display: flex;
`

const buttonStyles = css`
  margin-right: 1.6rem;
`

export {
  mainTitleStyles,
  subTitleStyles,
  mainTextStyles,
  checkboxStyles,
  tableParagraphStyles,
  buttonStyles,
  buttonInfoStyles,
  StyledSection,
  StyledTextWrapper,
  StyledTablesWrapper,
  StyledTableWrapper,
  StyledTableHeader,
  StyledTableContent,
  StyledTableItem,
  StyledButtonContainer,
  StyledButtonWrapper,
}
