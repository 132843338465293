const prepareEmailData = (emailProjects) => {
  const getEmailData = emailProjects
    .filter((proj) => proj.unselected === true)
    .map((filteredProj) => filteredProj.project.id)

  const emailProjectQuery = () => {
    const getEmailProjects = getEmailData.filter((item) => item !== "blog")
    return getEmailProjects.length
      ? `, emailProjectIds: [${getEmailProjects}]`
      : ""
  }

  const emailBlogQuery = () =>
    getEmailData.find((item) => item === "blog")
      ? `, unsubscribeBlog: true`
      : ""

  return `${emailProjectQuery()}${emailBlogQuery()}`
}

const preparePhoneData = (phoneProjects) => {
  const getPhoneData = phoneProjects.map((data) => {
    const getUnselectedProjects = data.phoneProjectSubscriptions
      .filter((project) => project.unselected === true)
      .map((filteredProject) => filteredProject.project.id)

    return getUnselectedProjects.length || data.unsubscribeBlog
      ? `{id: ${data.id},
      phoneNumber: "${data.phoneNumber}",
      projectIds: [${getUnselectedProjects}],
      unsubscribeBlog: ${data.unsubscribeBlog ? data.unsubscribeBlog : false}}`
      : ``
  })
  return `, phoneSubscriptions: [${getPhoneData}]`
}

const prepareData = (data) => {
  const { token, emailProjects, phoneProjects } = data

  const emailDataModel = prepareEmailData(emailProjects)
  const phoneDataModel = preparePhoneData(phoneProjects)

  return `token:"${token}"${emailDataModel}${phoneDataModel}`
}

const postUnsubscribe = async (data) => {
  const dataQuery = prepareData(data)

  const response = await fetch(process.env.GATSBY_API_URL, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: `Basic  ${process.env.GATSBY_API_BASE_AUTH}`,
    },
    body: JSON.stringify({
      query: `mutation{
                unsubscribe(data: {${dataQuery}}) {
                    ok
                  }
                }`,
      variables: null,
    }),
  })
  const body = await response.json()
  return body
}

export default postUnsubscribe
